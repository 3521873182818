<div class="video-container" [ngClass]="{ 'fut-skeleton': state() === 'LOADING', completed: completed() && !locked(), locked: locked() }">
  <div
    #videoCover
    (mouseleave)="geometryPositionHover(videoCover)"
    [ngClass]="{ 'bg-ghost': state() === 'GHOST' }"
    [class]="'live-container fut-p-12 ' + category()">
    <div class="d-flex flex-column live-text" [style.--video-cover-width.px]="videoCoverWidth()">
      <span class="live-title" *futGhostStateFragment="state(); textMinChar: 8; textMaxChar: 15">{{ videoTitle() }}</span>
      <span [innerHTML]="videoSubtitle()" class="live-subtitle fut-bold" *futGhostStateFragment="state(); textMinChar: 10; textMaxChar: 20"></span>
    </div>

    @if (state() === 'NONE') {
      <div class="live-square"></div>
      <img alt="triangle-img" class="live-triangle" src="/assets/triangle.svg" />
      <div class="live-circle"></div>
      @if (state() !== 'LOADING' && duration() && duration() >= 0) {
        <div class="duration">{{ durationToString() }}</div>
      }
      @if (completed() && !locked()) {
        <div class="completed-opacity"></div>
      }
    }
  </div>
  @if (state() !== 'LOADING') {
    <div class="live-play">
      @if (locked()) {
        <mat-icon fontSet="material-symbols-outlined">lock</mat-icon>
      } @else if (!completed()) {
        <mat-icon fontSet="material-symbols-outlined">play_arrow</mat-icon>
      } @else {
        <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
      }
    </div>
  }
</div>
