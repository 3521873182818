import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { LicenseLabelWhitelist, LicensePermission } from '../interfaces/user';
import { forkJoin, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from '../user-service/user.service';
import { checkLabel } from '../util/compare.util';
import { CacheBuilder } from '@futura/futura-ui/cache';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private readonly http = inject(HttpClient);
  private readonly userService = inject(UserService);

  private readonly permissionsCache = CacheBuilder.simple<string, { [key: string]: LicensePermission }>().build();

  private readonly defaultPermission: LicenseLabelWhitelist = {
    labels: [],
    whitelist: false,
  };

  load() {
    return this.permissionsCache.get('CACHE', () =>
      this.http
        .get<Array<LicensePermission>>(environment.be_url + '/users/permissions')
        .pipe(map(permissions => permissions.reduce((acc, permission) => ({ ...acc, [permission.value]: permission }), {})))
    );
  }

  get(permission: string): Observable<LicenseLabelWhitelist> {
    return this.load().pipe(map(permissions => permissions[permission] || this.defaultPermission));
  }

  check(permission: string): Observable<boolean> {
    return forkJoin([this.userService.getLabel(), this.get(permission)]).pipe(
      map(([lables, permission]) => checkLabel(permission.labels, permission.whitelist, lables))
    );
  }

  checkPage(page: string) {
    return this.check(`lock_page_${page}`);
  }
}
