<div (click)="toggleFrame()" [ngClass]="{ selected: showFrame }" class="flip-card">
  <div class="flip-card-inner">
    <div class="flip-card-front">
      <app-video-cover [media]="media || video || live" state="NONE"></app-video-cover>
    </div>
    <div class="flip-card-back">
      @if (safeFrameHtml && showFrame) {
        <div #videoContainer [style]="customStyle" [innerHTML]="safeFrameHtml"></div>
      }
    </div>
  </div>
</div>
