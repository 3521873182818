import { VideoCategory } from '../../../core/interfaces/video-category';
import { UtilsService } from 'src/app/core/utils-service/utils.service';
import { PERCENT_FOR_VIDEO_SEND_GTAG_EVENT, PERCENT_FOR_VIDEO_WATCHED } from '../../constant';
import { User } from 'src/app/core/interfaces/user';
import { UserService } from 'src/app/core/user-service/user.service';
import Player from '@vimeo/player';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Meeting } from 'src/app/core/interfaces/meeting';
import { Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ArchiveService } from 'src/app/view/home/archive/service/archive.service';
import { pathToCategories } from '../../../core/util/category.util';
import { isVideoCategory } from '../../../core/util/video.util';
import { firstValueFrom, Observable, ReplaySubject, takeLast } from 'rxjs';
import { NgClass } from '@angular/common';
import { VideoCoverComponent } from '../video-cover/video-cover.component';
import { getTime } from '../../../core/util/date.util';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ANALYTICS_PROVIDER } from '@futura/futura-ui';

@Component({
  selector: 'app-frame-vimeo-live',
  templateUrl: './frame-vimeo-live.component.html',
  styleUrls: ['./frame-vimeo-live.component.scss'],
  standalone: true,
  imports: [NgClass, VideoCoverComponent],
})
export class FrameVimeoLiveComponent implements OnInit, OnChanges, OnDestroy {
  private readonly analyticsService = inject(ANALYTICS_PROVIDER);
  @Input() public media?: Meeting | VideoCategory;
  @Input() public live?: Meeting;
  @Input() public video?: VideoCategory;

  @Input() public milliseconds?: number;
  @Input() public startVideo?: boolean;
  @Output() public readonly playerChange = new EventEmitter<Player>();

  @Input() public frameVideoTitle = '';

  public showFrame = false;
  public safeFrameHtml?: SafeHtml;
  @Input() customStyle = 'height: 100%;';
  @Input() customFrameStyle: Partial<CSSStyleDeclaration> = {};
  private readonly playerReady = new ReplaySubject<void>(1);
  private player?: Player;
  private user?: User;
  private intervalHandler?: ReturnType<typeof setInterval>;
  private cat1 = '';
  private videoId = '';
  private gtagEventSent = false;
  private isLive = false;

  constructor(
    private userService: UserService,
    private utilsService: UtilsService,
    private sanitizer: DomSanitizer,
    private archiveService: ArchiveService,
    private destroyRef: DestroyRef
  ) {}

  @ViewChild('videoContainer')
  public set videoContainer(containerRef: ElementRef<HTMLElement>) {
    if (!containerRef) {
      return;
    }

    const iframe = containerRef.nativeElement.querySelector('iframe');
    if (!iframe) {
      return;
    }

    this.initFrame(iframe);
  }

  ngOnInit(): void {
    this.userService.getFuturaUser().subscribe(user => (this.user = user));

    if (this.media && !this.live && !this.video) {
      if (isVideoCategory(this.media)) {
        this.video = this.media;
      } else {
        this.live = this.media;
      }
    }
    if (this.live && this.live.content.recording && this.live.content.recording.frame_html) {
      this.safeFrameHtml = this.sanitizerHtml(this.live.content.recording.frame_html);
      this.cat1 = this.live.content.categories[0].split('.')[0];
      this.videoId = this.live.content.id;
      this.isLive = true;
    }

    if (this.video) {
      this.safeFrameHtml = this.sanitizerHtml(this.video.video_frame);
      this.cat1 = this.video.category_path.split('.')[0];
      this.videoId = this.video.video_uuid;
      this.isLive = false;
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.milliseconds?.currentValue) {
      if (!this.showFrame) {
        await this.toggleFrame();
      }
      const milliseconds = changes.milliseconds?.currentValue as number;

      this.player?.setCurrentTime(milliseconds / 1000);
    }
    if (changes.startVideo) {
      const startVideo = !!changes.startVideo.currentValue;
      if (!startVideo || this.showFrame) {
        return;
      }
      await this.toggleFrame();
    }
  }

  ngOnDestroy(): void {
    this.playerReady.complete();
    this.playerReady.unsubscribe();
    if (this.intervalHandler) {
      clearInterval(this.intervalHandler);
    }
    if (this.player) {
      this.player.off('play');
      this.player.off('pause');
      this.player.off('ended');
      this.player.off('cuepoint');
      this.player.destroy();
    }
  }

  sanitizerHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public async toggleFrame(): Promise<void> {
    this.showFrame = true;
    if (this.showFrame) {
      if (this.player) {
        return;
      }

      await firstValueFrom(this.playerReady.asObservable());
      return;
    }

    if (!this.player) {
      return;
    }
    await this.player.pause();
    this.updateLiveTime(this.videoId, await this.player.getCurrentTime(), await this.player.getDuration());
    this.player.off('play');
    this.player.off('pause');
    this.player.off('ended');
  }

  private initFrame(element: HTMLIFrameElement): void {
    element.style.width = '100%';
    element.style.height = '100%';
    element.style.backgroundColor = 'black';
    element.setAttribute('loading', 'lazy');

    for (const key in this.customFrameStyle) {
      element.style[key] = this.customFrameStyle[key] as string;
    }

    const cat1 = this.cat1;
    const subject_color = getComputedStyle(document.documentElement).getPropertyValue('--' + cat1);

    const player = new Player(element);
    this.player = player;

    this.player.play();
    this.player.setColor(subject_color);

    const lastLiveView = this.user?.content.video_list?.find(video => video.live_id == this.videoId);

    if (lastLiveView && lastLiveView.is_ended) return;

    if (lastLiveView) this.player.setCurrentTime(lastLiveView.time);

    this.player.on('play', () => {
      if (this.intervalHandler) clearInterval(this.intervalHandler);

      this.intervalHandler = setInterval(async () => {
        this.updateLiveTime(this.videoId, await player.getCurrentTime(), await player.getDuration());
      }, 5000);
    });

    this.player.on('pause', async () => {
      if (this.intervalHandler) clearInterval(this.intervalHandler);
      const liveUpdate = this.updateLiveTime(this.videoId, await player.getCurrentTime(), await player.getDuration());
      // this.toggleFrame();
      if (liveUpdate) {
        liveUpdate.subscribe(() => {
          this.archiveService.updateCategories().subscribe();
        });
      }
    });

    this.player.on('ended', async () => {
      if (this.intervalHandler) clearInterval(this.intervalHandler);
      const liveUpdate = this.updateLiveTime(this.videoId, await player.getCurrentTime(), await player.getDuration());
      // this.toggleFrame();
      if (liveUpdate) {
        liveUpdate.subscribe(() => {
          this.archiveService.updateCategories().subscribe();
        });
      }
    });

    this.player.getDuration().then(duration => {
      const cuepointTime = Math.floor(duration * (PERCENT_FOR_VIDEO_SEND_GTAG_EVENT / 100));

      player.addCuePoint(cuepointTime, { type: 'send_gtag_event' }).then(cue => {
        console.log('Added cue', cue);
      });
    });

    this.player.on('cuepoint', async cue => {
      console.log('cuepoint', cue);
      if (cue.data.type == 'send_gtag_event') {
        if (this.gtagEventSent) return;
        this.gtagEventSent = true;

        const gtag_video_params = {
          video_id: this.video ? this.videoId : this.live?.content.id || '',
          video_title: this.video ? this.video.video_title : this.live?.content.title || '',
          video_category: this.video ? this.video.category_path : this.live?.content.categories[0] || '',
          video_type: this.video ? 'video' : 'live',
        };

        const duration = await this.player?.getDuration();
        this.analyticsService?.sendAnalytics({
          eventName: 'action_video_complete',
          eventParams: {
            title: gtag_video_params.video_title,
            video_id: gtag_video_params.video_id,
            duration,
            type: gtag_video_params.video_type === 'video' ? 'on_demand' : 'recorded_live',
          },
        });

        const [cat1, cat2, cat3] = pathToCategories(gtag_video_params.video_category);

        this.utilsService.pushDataLayer({
          eventName: 'lesson_viewed',
          search_term: null,
          position: gtag_video_params.video_type === 'video' ? 'on_demand' : 'live_room',
          label: gtag_video_params.video_title,
          action: null,
          cat1: cat1,
          cat2: cat2 || null,
          cat3: cat3 || null,
          status: null,
          date: null,
          order: null,
        });
      }
    });
    this.playerReady.next();
    this.playerChange.next(player);

    fromPromise(this.player?.getDuration())
      .pipe(takeLast(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(duration => {
        this.analyticsService?.sendAnalytics({
          eventName: 'action_video_start',
          eventParams: {
            title: this.video ? this.video.video_title : this.live?.content.title || '',
            video_id: this.video?.video_uuid,
            duration,
            type: this.video ? 'video' : 'live',
          },
        });
      });
  }

  private updateLiveTime(videoId: string, current: number, total: number): undefined | Observable<void> {
    if (!this.user) return;
    if (!this.user.content.video_list) this.user.content.video_list = [];

    const lastLiveTime = this.user.content.video_list.find(video => video.live_id == videoId);

    if (lastLiveTime && (lastLiveTime.time > current || lastLiveTime.is_ended)) return;

    const savingLiveTime = lastLiveTime || {
      live_id: videoId,
      is_ended: false,
      last_time_watched: getTime(),
      time: current,
      total: total,
      is_video: !this.isLive,
    };

    const percent = (current / total) * 100;

    savingLiveTime.is_ended = percent >= PERCENT_FOR_VIDEO_WATCHED;
    savingLiveTime.time = savingLiveTime.is_ended ? savingLiveTime.total : current;
    savingLiveTime.last_time_watched = getTime();
    savingLiveTime.is_video = !this.isLive;

    // Replacing the old video info or creating the new one
    if (lastLiveTime) this.user.content.video_list = this.user.content.video_list.map(video => (video.live_id == videoId ? savingLiveTime : video));
    else this.user.content.video_list.push(savingLiveTime);

    return this.saveLiveTimes();
  }

  private saveLiveTimes(): Observable<void> | undefined {
    if (!this.user) return;
    return this.userService.updateFuturaUser(this.user);
  }
}
