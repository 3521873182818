import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { GhostOverlayComponent } from '../../component/ghost-overlay/ghost-overlay.component';
import { GhostOverlayModel } from '../../model/ghost-overlay.model';
import { UserService } from '../../../../core/user-service/user.service';
import { Subscription } from 'rxjs';
import { COMPONENT_STATE, ComponentState } from '@futura/futura-ui/ghost';

@Directive({
  selector: '[appGhostOverlay]',
  standalone: true,
})
export class GhostOverlayDirective implements OnChanges, OnDestroy {
  @Input() public ghostConfig: Partial<GhostOverlayModel> = {};
  @Input() public lockConfig: Partial<GhostOverlayModel> = {};

  @Input() public state?: ComponentState = COMPONENT_STATE.NONE;

  @Input() public locked?: boolean;
  @Input() public userLabels?: Array<string>;
  @Input() public labels?: { lables: Array<string>; whitelist: boolean };

  @Input() public customStyle?: Partial<CSSStyleDeclaration>;
  @Output() public readonly ghostClick = new EventEmitter<void>();
  @Output() public readonly lockClick = new EventEmitter<void>();
  private oldStyle: Partial<CSSStyleDeclaration> = {};
  @HostBinding('class.position-relative') private showOverlay = false;

  private componentRef?: ComponentRef<GhostOverlayComponent> | null;
  private subscription?: Subscription | null;

  constructor(
    private element: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef,
    private userService: UserService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.state || changes.locked) {
      this.toggleOverlay();
    }

    if (changes.userLabels || changes.labels) {
      this.updateLock();
      this.toggleOverlay();
    }
  }

  ngOnDestroy(): void {
    this.removeGhostOverlay();
  }

  private updateLock(): void {
    if (!this.userLabels || !this.labels) {
      return;
    }
    const { lables, whitelist } = this.labels;
    this.locked = this.userService.checkLables(this.userLabels, lables, whitelist);
  }

  private toggleOverlay(): void {
    if (!this.locked && (!this.state || this.state === COMPONENT_STATE.NONE)) {
      this.removeGhostOverlay();
      this.showOverlay = false;
    } else {
      this.addGhostOverlay();
      this.showOverlay = true;
    }
    this.changeStyle();
  }

  private addGhostOverlay(): void {
    this.removeGhostOverlay();
    const config = this.locked ? this.lockConfig : this.ghostConfig;
    this.componentRef = this.createGhostOverlay(config);
    const nativeElement = this.element.nativeElement;
    nativeElement.insertBefore(this.componentRef.location.nativeElement, nativeElement.firstChild);
  }

  private createGhostOverlay(config: Partial<GhostOverlayModel>): ComponentRef<GhostOverlayComponent> {
    const componentRef = this.viewContainerRef.createComponent(GhostOverlayComponent);
    const instance: GhostOverlayComponent = componentRef.instance;
    this.fillInstance(config, instance);

    this.subscription = instance.ghostClick.subscribe(() => this.onActionClick());

    return componentRef;
  }

  private fillInstance(config: Partial<GhostOverlayModel>, instance: GhostOverlayComponent): void {
    if (config.icon || config.icon === null) {
      instance.icon = config.icon;
    }

    if (this.state === COMPONENT_STATE.LOADING) {
      instance.icon = 'loading';
      return;
    }

    if (config.fontSet) {
      instance.fontSet = config.fontSet;
    }
    if (config.title) {
      instance.ghostTitle = config.title;
    }
    if (config.actionButton) {
      instance.actionButton = config.actionButton;
    }

    if (config.menuTemplate) {
      instance.menuTemplate = config.menuTemplate;
    }

    if (config.titleClass) {
      instance.titleClass = config.titleClass;
    }

    if (config.content) {
      instance.content = config.content;
    }

    if (config.contentClass) {
      instance.contentClass = config.contentClass;
    }
  }

  private onActionClick(): void {
    if (this.locked) {
      this.userService.missingLicenseDialog();
      this.lockClick.emit();
    } else {
      this.ghostClick.emit();
    }
  }

  private changeStyle(): void {
    if (!this.showOverlay) {
      for (const s in this.oldStyle) {
        this.element.nativeElement.style[s] = this.oldStyle[s] as string;
      }
    } else {
      if (!this.customStyle) {
        return;
      }
      for (const styleKey in this.customStyle) {
        this.oldStyle[styleKey] = this.element.nativeElement.style[styleKey];
        this.element.nativeElement.style[styleKey] = this.customStyle[styleKey] as string;
      }
    }
  }

  private removeGhostOverlay(): void {
    this.componentRef?.destroy();
    this.subscription?.unsubscribe();
    this.componentRef = null;
    this.subscription = null;
  }
}
