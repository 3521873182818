export function compareObj(a: unknown, b: unknown): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function compareAttribute<T>(attribute: keyof T): (a: T, b: T) => 0 | 1 | -1 {
  return (a: T, b: T) => {
    const attrA = a[attribute];
    const attrB = b[attribute];
    return campareValue(attrA, attrB);
  };
}

export function campareValue<T>(a: T, b: T): 0 | 1 | -1 {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export function checkLabel(elementLabels: string[], elementWhitelist: boolean | undefined, userLables: string[], forceUnlock: boolean = false): boolean {
  if (forceUnlock) return true;
  // return false;
  if (elementWhitelist && elementLabels.length == 0) return false;
  if (!elementWhitelist && elementLabels.length == 0) return true;

  if (elementWhitelist) return userLables.some(x => (elementLabels || []).includes(x));
  else return !userLables.some(x => (elementLabels || []).includes(x));
}
