import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { ButtonMenuContentComponent } from '@futura/futura-ui/button';
import { MatMenuTrigger, MatMenu, MatMenuContent } from '@angular/material/menu';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-ghost-overlay',
  templateUrl: './ghost-overlay.component.html',
  styleUrls: ['./ghost-overlay.component.scss'],
  standalone: true,
  imports: [MatIcon, NgClass, MatMenuTrigger, MatMenu, MatMenuContent, ButtonMenuContentComponent, NgTemplateOutlet, TranslocoPipe],
})
export class GhostOverlayComponent {
  @Input() public icon: string | null = 'lock';
  @Input() public fontSet = 'material-symbols-outlined';

  @Input() public ghostTitle?: string;
  @Input() public titleClass?: string;
  @Input() public content?: string;
  @Input() public contentClass?: string;

  @Input() public actionButton?: string;
  @Input() public menuTemplate?: TemplateRef<unknown>;

  @Output() public readonly ghostClick = new EventEmitter<void>();
}
